import axios from 'axios';
import env from '../env';

const baseURL = env.BACKEND_BASE_URL + 'api/';

const http = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
    }, 
    withCredentials: true
});


export default http;