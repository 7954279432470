export class LicenseCategoryClass {    
  
    constructor(data){
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.image = data.image;
        this.link = data.link;
        this.description = data.description;
    }
}