/* eslint-disable array-callback-return */
// @ts-nocheck
import React, { useContext, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import 'css/index.css';
import App from './app';
import 'css/styles.css';
import 'font-awesome/css/font-awesome.min.css';

import { ActaAuthContext } from "./pa_kit/auth";


const AppRoutes = () => {

    const auth = useContext(ActaAuthContext);

    const theme = auth.theme;  

    const defaultTheme = {
        "--color-primary": theme.primary_color,
        "--color-secondary": theme.secondary_color,
        "--color-tertiary": theme.tertiary_color,
        "--color-quaternary": theme.quaternary_color,
    }    

    const applyTheme = (theme) => {
        Object.keys(theme).map((key) => {
            const value = theme[key];
            document.documentElement.style.setProperty(key, value);
        });
    };
    
    applyTheme(defaultTheme);

    const user = auth.user;

    const Homepage = lazy(() => import('apps/pages/components/homepage'));  
    const DatasetPage = lazy(() => import('apps/datasets/components/dataset_page'));
    const Datasets = lazy(() => import('apps/datasets/components/datasets'));
    
    const Dashboard = lazy(() => import('admin/dashboard/dashboard'));
    const Main = lazy(() => import('admin/dashboard/main'));
    const DatasetsList = lazy(() => import('admin/datasets/datasets_list'));
    const CreateDataset = lazy(() => import('admin/datasets/create_dataset'));
    const EditDataset = lazy(() => import('admin/datasets/edit_dataset'));
    const ActivitiesList = lazy(() => import('admin/activities/activities_list'));

    if(auth.isLoading) return <div></div>;
    return <>
        <BrowserRouter>
            <Suspense fallback={<div></div>}>
                <Routes>
                    <Route path="/" element={<App />} >
                        <Route path="" element={<Homepage />} ></Route>
                        <Route path="datasets/" element={<Datasets />} ></Route>                                     
                        <Route path="datasets/:categorySlug" element={<Datasets />} ></Route>
                        <Route path="datasets/:categorySlug/:datasetSlug" element={<DatasetPage />} ></Route>                                     
                    </Route>

                    <Route path="/admin" element={user && user.is('dati') ? <Dashboard /> : <Navigate to="/" />} >
                        <Route path="" element={<Main />} ></Route>
                        <Route path="create-dataset" element={<CreateDataset />}></Route>
                        <Route path="edit-dataset/:datasetSlug" element={<EditDataset />}></Route>
                        <Route path="datasets" element={<DatasetsList />}></Route>
                        <Route path="activities" element={<ActivitiesList />}></Route>
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    </>;
}

export default AppRoutes;

        
        