import { SubcategoryClass } from '../../categories/models/subcategory';
import { CategoryClass } from '../../categories/models/category';
import { LicenseCategoryClass } from '../../licenses/models/license_category';
import { LicenseSubcategoryClass } from '../../licenses/models/license_subcategory';
import { TagClass } from '../../tags/models/tag';

export const DatasetMetadataSections =  {
    dataset_id: "Identificativo del dataset",
    dataset_alt_id: "Altro identificativo",
    dataset_theme: "Temi del dataset",
    dataset_editor: "Editore del dataset",
    release_date: "Data di rilascio",
    edit_date: "Data di modifica",
    geo_coverage: "Copertura geografica",
    geonames_uri: "URI di GeoNames",
    dataset_language: "Lingue del dataset",
    temporal_range: "Estensione Temporale",
    holder: "Titolare",
    update_frequency: "Frequenza di aggioranmento",
    version: "Versione di",
    author: "Autore",
}

export const DatasetMoreInfoSections =  {
    keeper: "Manutentore",
    last_update: "Ultimo Aggiornamento",
    created_date: "Creato"
}

export class DatasetClass {    
  
    constructor(data){
        this.id = data.id;
        this.title = data.title;
        this.slug = data.slug;
        this.category = new CategoryClass(data.category_data);
        this.subcategory = data.subcategory !== null ? new SubcategoryClass(data.subcategory_data) : '';
        this.description = data.description;
        this.license_category = new LicenseCategoryClass(data.license_category_data);
        this.license_subcategory = data.license_subcategory !== null ? new LicenseSubcategoryClass(data.license_subcategory_data) : '';
        this.tags = data.tags_data?.map((tag) => new TagClass(tag)) || [];
        this.dataset_metadata = data.dataset_metadata;
        this.dataset_more_info = data.dataset_more_info;
        this.attachments = data.attachments;
        this.release_date = data.release_date;
        this.published_date = data.published_date;
        this.created_date = data.created_date;
        this.last_update = data.last_update;
    }
}

