import { CategoryClass } from './category';

export class SubcategoryClass {    
  
    constructor(data){
        this.id = data.id;
        this.category = new CategoryClass(data.category_data);
        this.code = data.code
        this.name = data.name;
        this.slug = data.slug;
        this.image = data.image;
        this.link = data.link;
        this.description = data.description;
    }
}