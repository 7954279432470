import { LicenseCategoryClass } from './license_category';

export class LicenseSubcategoryClass {    
  
    constructor(data){
        this.id = data.id;
        this.license_category = new LicenseCategoryClass(data.license_category_data);
        this.name = data.name;
        this.slug = data.slug;
        this.image = data.image;
        this.link = data.link;
        this.description = data.description;
    }
}