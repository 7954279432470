/* eslint-disable react-hooks/exhaustive-deps */
import { ActivityClass } from 'apps/activities/models/activity';
import http from './http';
import { CategoryClass } from 'apps/categories/models/category';
import { SubcategoryClass } from 'apps/categories/models/subcategory';
import { DatasetClass } from 'apps/datasets/models/dataset';
import { FormatClass } from 'apps/formats/models/format';
import { LicenseCategoryClass } from 'apps/licenses/models/license_category';
import { LicenseSubcategoryClass } from 'apps/licenses/models/license_subcategory';
import { TagClass } from 'apps/tags/models/tag';


class ApiService {

    // Get Category
    async getCategory(slug) {
        let result = await http.get('categories/' + slug);
        return result.data; 
    }

    // Get Category List
    async getCategoryList() {
        let result = await http.get('categories/');
        return result.data.map((data) => new CategoryClass(data)); 
    }

    // Get Subcategory
    async getSubcategory(slug) {
        let result = await http.get('subcategories/' + slug);
        return result.data; 
    }

    // Get Category List
    async getSubcategoryList() {
        let result = await http.get('subcategories/');
        return result.data.map((data) => new SubcategoryClass(data)); 
    }

    // Get Subcategories By Category
    async getSubcategoriesByCategory(category) {
        let result = await http.get('subcategories/category/' + category);
        return result.data.map((data) => new SubcategoryClass(data)); 
    }

    // Get Dataset List
    async getDatasetList() {
        let result = await http.get('datasets/');
        return result.data.map((data) => new DatasetClass(data)); 
    }

    // Get Dataset By Category
    async getDatasetsByCategory(category) {
        let result = await http.get('datasets/category/' + category);
        return result.data.map((data) => new DatasetClass(data)); 
    }

    // Get Dataset
    async getDataset(slug) {
        let result = await http.get('datasets/' + slug);
        return result.data; 
    }

    // Create Dataset
    async createDataset(formData) {
        let result = await http.post('datasets/', {...formData});
        return result;
    }

    // Edit Dataset
    async editDataset(slug, formData) {
        let result = await http.patch('datasets/' + slug + '/', {...formData });
        return result;
    }

    // Delete Dataset
    async deleteDataset(slug) {
        let result = await http.delete('datasets/' + slug + '/');
        return result;
    }

    // Get Tag List
    async getTagList() {
        let result = await http.get('tags/');
        return result.data.map((data) => new TagClass(data)); 
    }

    // Create Tag
    async createTag(formData) {
        let result = await http.post('tags/', {...formData});        
        return result;
    }

    // Get License Category List
    async getLicenseCategoryList() {
        let result = await http.get('license_categories/');
        return result.data.map((data) => new LicenseCategoryClass(data)); 
    }

    // Get License Subcategory List
    async getLicenseSubcategoryList() {
        let result = await http.get('license_subcategories/');
        return result.data.map((data) => new LicenseSubcategoryClass(data)); 
    }

    // Get License Subcategories By License Category
    async getLicenseSubcategoriesByLicenseCategory(license_category) {
        let result = await http.get('license_subcategories/license_category/' + license_category);
        return result.data.map((data) => new LicenseSubcategoryClass(data)); 
    }

    // Get Format List
    async getFormatList() {
        let result = await http.get('formats/');
        return result.data.map((data) => new FormatClass(data)); 
    }

    // Upload File
    async uploadFile(file, source) {
        const formData = new FormData();

        switch(source) {

            default:
                formData.append("source", "standard");
        }
        
        formData.append("file", file);

        const filenameHeaderValue = 'inline;filename=' + encodeURIComponent(file.name);

        const result = await http.put('/file/upload/', formData, 
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Content-Disposition': filenameHeaderValue,
                    "Access-Control-Allow-Origin": "*"
                }
            }
        );
        return result.data;
    }

    // Get Activities
    async getActivities() {
        let result = await http.get('activities/');       
        return result.data.map((data) => new ActivityClass(data));
    }   
}

export default new ApiService();


